export const TEXT_GROUP_RESPONSE_NAME = 'textGroup';
export const QUICK_REPLY_RESPONSE_NAME = 'quickReplies';
export const AUDIO_RESPONSE_NAME = 'audio';
export const CARDS_RESPONSE_NAME = 'cards';
export const LOCATION_RESPONSE_NAME = 'location';
export const FILE_RESPONSE_NAME = 'file';
export const WAIT_RESPONSE_NAME = 'wait';
export const MAX_DETAIL_COUNT = 3;
export const ALL_CATEGORY_NAME = 'All';
export const IS_TIMEOUT_ENABLED_INPUT_NAME = 'isTimeoutEnabled';
export const TIMEOUT_INPUT_NAME = 'timeout';
export const TIMEOUT_DURATION_INPUT_NAME = 'timeoutDuration';
export const TIMEOUT_DEFAULT_VALUE = '600';
export const MAXIMUM_FILE_RESPONSE_ALLOWED = 5;
export const TIMEOUT_PORT_NAME = 'timeout';
export const TIMEOUT_INPUT_EXTRA_PROPERTIES_IS_VISIBLE_KEY = 'timeout:isVisible';

export const nodeColor = {
  ask: '#f7b538',
  askAttachment: '#B4A7D6',
  askMissingSlot: '#a68a64',
  callTransfer: '#ffb4a2',
  code: '#52796f',
  condition: '#77c47c',
  confirmation: '#d68c45',
  customData: '#86a984',
  empty: '#e8685a',
  end: '#cf7272',
  entityExtract: '#c2ab9c',
  http: '#ffc7e9',
  intentRecognition: '#62b6cb',
  listFilter: '#476c5b',
  liveAgent: '#f08080',
  say: '#e38664',
  start: '#b3edb9',
  subFlow: '#d5a6bd',
  variable: '#9fbba5',
  virtualAgent: '#90dcda',
  voiceBiometrics: '#b0bae6',
  wait: '#a88b99',
  warning: '#e5e5e5',
  connector: '#77a5b1',
  exit: '#9f3939',
  returnToStart: '#cf7272',
  generativeQuestionAnswering: '#5CD4E5',
  languageCondition: '#4755bc',
};

export const nodeNames = {
  start: 'start',
  end: 'end',
  say: 'say',
  ask: 'ask',
  askMissingSlot: 'askMissingSlot',
  askAttachment: 'askAttachment',
  http: 'http',
  variable: 'variable',
  listFilter: 'listFilter',
  customData: 'customData',
  code: 'code',
  intentRecognition: 'intentRecognition',
  liveAgent: 'liveAgent',
  callTransfer: 'callTransfer',
  wait: 'wait',
  subFlow: 'subFlow',
  virtualAgent: 'virtualAgent',
  condition: 'condition',
  confirmation: 'confirmation',
  voiceBiometrics: 'voiceBiometrics',
  connector: 'connector',
  exit: 'exit',
  returnToStart: 'returnToStart',
  generativeQuestionAnswering: 'generativeQuestionAnswering',
  languageCondition: 'languageCondition',
};

export const languageCodeNameMap = new Map<string, string>([
  ['tr-TR', 'Turkish'],
  ['en-US', 'English'],
  ['ar-SA', 'Arabic'],
  ['lv-LV', 'Latvian'],
  ['ru-RU', 'Russian'],
  ['es-ES', 'Spanish'],
  ['ur-PK', 'Urdu'],
  ['fr-FR', 'French'],
  ['nl-NL', 'Dutch'],
  ['de-DE', 'German'],
  ['it-IT', 'Italian'],
]);
